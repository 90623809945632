export const prices = [
    {
        "id": 0,
        "name": "Any",
        "array": [0,50000]
    },
    {
        "id": 1,
        "name": "€50 to €150",
        "array": [50, 150]
    },
    {
        "id": 2,
        "name": "€151 to €250",
        "array": [151, 250]
    },
    {
        "id": 3,
        "name": "€251 to €350",
        "array": [251, 350]
    },
    {
        "id": 4,
        "name": "€351 to €450",
        "array": [351, 450]
    },
    {
        "id": 5,
        "name": "€451 to €650",
        "array": [451, 650]
    },
    {
        "id": 6,
        "name": "€651 to €850",
        "array": [651, 850]
    },
    {
        "id": 7,
        "name": "More than €851",
        "array": [851, 50000]
    }
]